<template>
  <v-container class="container--fluid fill-height">
    <v-row no-gutters align="center" justify="center">
      <v-col cols="12" sm="8" md="4" lg="4">
        <v-card class="elevation-5 pa-3">
          <v-card-text>
            <div class="layout column align-center">
              <h1 class="text-center my-4 primary--text">
                LOGIN(23.09.27{{ wakeLock }})
              </h1>
            </div>
            <v-form ref="form">
              <v-text-field
                v-model="model.server"
                append-icon="mdi-server"
                name="server"
                label="* 거래 서버 [ip:port,ip:port...]"
                type="text"
                required
                :rules="[(v) => !!v || '서버 정보를 입력해주세요.']"
                autocomplete="server"
              />
              <v-text-field
                v-model="model.subServer"
                append-icon="mdi-server"
                name="server"
                label="보조 서버 [ip:port,ip:port...]"
                type="text"
                required
                autocomplete="server"
              />
              <v-text-field
                v-model="model.secret"
                append-icon="mdi-lock"
                name="secret"
                label="* 비밀키"
                type="password"
                required
                :rules="[(v) => !!v || '인증키를 입력해주세요.']"
                autocomplete="current-password"
              />
              <span class="red--text">{{ errorMsg }}</span>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" block :loading="isLogin" @click="login()">
              START
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      valid: true,
      model: {
        secret: "",
        server: "",
        subServer: "",
      },
      errorMsg: "",
      wakeLock: "",
    };
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
  },
  created() {
    this.model.server = localStorage.getItem("server")
      ? localStorage.getItem("server")
      : "";
    this.model.subServer = localStorage.getItem("subServer")
      ? localStorage.getItem("subServer")
      : "";
    this.model.secret = localStorage.getItem("secret")
      ? localStorage.getItem("secret")
      : "";

    if ("wakeLock" in navigator) {
      this.wakeLock = "Wake Lock"
    }
  },
  methods: {
    login() {
      if (!this.$refs.form.validate()) {
        return;
      }

      var ips = this.model.server ? this.model.server.split(",") : [];
      var subIps = this.model.subServer ? this.model.subServer.split(",") : [];

      this.$store
        .dispatch("login", { ips: ips, subIps: subIps, pw: this.model.secret })
        .then(() => {
          localStorage.setItem("server", this.model.server);
          localStorage.setItem("subServer", this.model.subServer);
          localStorage.setItem("secret", this.model.secret);
          this.$router.push("/");
        })
        .catch(() => {
          this.errorMsg = "서버 연결이 실패하였습니다.";
        });
    },

    validate() {
      this.$refs.form.validate();
    },
    addToHome() {
      if (!window.promptEvent) {
        return;
      }
      try {
        // show the install app prompt
        window.promptEvent.prompt();
        // handle the Decline/Accept choice of the user
        window.promptEvent.userChoice.then(function () {
          window.promptEvent = null;
        });
      } catch (e) {
        alert(e);
      }
    },
  },
};
</script>
